import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

import passport from '../../images/passport/kongsir.jpg';

const Kongsir = () => {
  const title = 'About Kongsir';

  const photos = useStaticQuery(graphql`
    query kongsirPhotos {
      allFile(
        filter: { relativeDirectory: { eq: "kongsir" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <div className="passport">
        <img src={passport} alt="Kongsir passport" />

        <div>
          <p>Welcome to our big family of Wing Chun!</p>

          <p>My name in Chinese is 江覺峰.</p>

          <p>I am married with two adult daughters.</p>

          <p>
            In 1969 I started learning Wing Chun from my Sifu Leung Sheung
            (first student of Grandmaster Ip Man in Hong Kong and eldest Kung-fu
            brother of Bruce Lee) and became the chairman of Leung Sheung Wing
            Chun Association until he died in 1978. Since then I have been
            teaching Wing Chun part-time most of the time until now.
          </p>

          <p>
            I received my primary, secondary and university education in Hong
            Kong, and served as a secondary English teacher for nearly 20 years
            before I went to Australia in 1994 with my family. Unable to find a
            job, I ran an ice-cream shop for 4 years and it had to close down,
            and I had to eat all the unsold ice-cream, which nearly killed me.
            The good side was that I got my Master Degree in Applied Linguistics
            in Australia. In 2011 I returned to Hong Kong.
          </p>

          <p>
            My Sifu often said, "You find me, you are lucky." I hope that I can
            say the same to my students in future.
          </p>
        </div>
      </div>

      <hr />

      <h2>Photo gallery</h2>

      {photos.allFile.edges.map(({ node }) => (
        <Img fluid={node.childImageSharp.fluid} className="photo" />
      ))}
    </Layout>
  );
};

export default Kongsir;
